export const adjectives = [
  "adept",
  "affable",
  "affectionate",
  "agreeable",
  "alert",
  "alluring",
  "ambitious",
  "amiable ",
  "amicable ",
  "amusing",
  "attentive",
  "awesome",
  "boundless",
  "brave",
  "bright",
  "broad-minded",
  "calm",
  "capable",
  "careful",
  "charming",
  "cheerful",
  "clever",
  "coherent",
  "comfortable",
  "communicative",
  "compassionate",
  "confident",
  "conscientious",
  "considerate",
  "consistent",
  "convivial",
  "cooperative",
  "courageous",
  "courteous",
  "creative",
  "credible",
  "cultured",
  "dashing",
  "dazzling",
  "debonair",
  "decisive",
  "decorous",
  "delightful",
  "detailed",
  "determined",
  "diligent",
  "diplomatic",
  "discerning",
  "discreet",
  "dynamic",
  "eager",
  "easygoing",
  "eclectic",
  "efficient",
  "elated",
  "eminent",
  "emotional",
  "enchanting",
  "encouraging",
  "endurable",
  "energetic",
  "enterprising",
  "entertaining",
  "enthusiastic",
  "excellent",
  "excited",
  "exclusive",
  "exuberant",
  "fair",
  "fair-minded",
  "faithful",
  "fantastic",
  "fearless",
  "fine",
  "forceful",
  "frank",
  "friendly",
  "funny",
  "generous",
  "gentle",
  "glorious",
  "good",
  "gregarious",
  "groundbreaking",
  "happy",
  "hard-working",
  "harmonious",
  "helpful",
  "hilarious",
  "honest",
  "honorable",
  "humorous",
  "imaginative",
  "impartial",
  "independent",
  "industrious",
  "ingenious",
  "inscrutable",
  "insightful",
  "instinctive",
  "intellectual",
  "intelligent",
  "intuitive",
  "inventive",
  "jolly",
  "joyous",
  "kind",
  "kind-hearted",
  "knowledgeable",
  "level",
  "likeable",
  "lively",
  "logical",
  "lovely",
  "loving",
  "loyal",
  "lucky",
  "mature",
  "meticulous",
  "modern",
  "modest",
  "multi-talented",
  "neat",
  "nice",
  "obedient",
  "optimistic",
  "organized",
  "painstaking",
  "passionate",
  "patient",
  "peaceful",
  "perceptive",
  "perfect",
  "persistent",
  "philosophical",
  "pioneering",
  "placid",
  "plausible",
  "pleasant",
  "plucky",
  "polite",
  "powerful",
  "practical",
  "proactive",
  "productive",
  "professional",
  "protective",
  "proud",
  "punctual",
  "quick-witted",
  "quiet",
  "rational",
  "receptive",
  "reflective",
  "reliable",
  "relieved",
  "reserved",
  "resolute",
  "resourceful",
  "responsible",
  "rhetorical",
  "righteous",
  "robust",
  "romantic",
  "sedate",
  "seemly",
  "selective",
  "self-assured",
  "self-confident",
  "self-disciplined",
  "sensible",
  "sensitive",
  "sharp",
  "shrewd",
  "shy",
  "silly",
  "sincere",
  "skillful",
  "smart",
  "smiling",
  "sociable",
  "sophisticated",
  "splendid",
  "steadfast",
  "stimulating",
  "straightforward",
  "strategic",
  "studious",
  "successful",
  "succinct",
  "sympathetic",
  "talented",
  "thoughtful",
  "thrifty",
  "tidy",
  "tough",
  "trustworthy",
  "unassuming",
  "unbiased",
  "understanding",
  "unprecedented",
  "unusual",
  "upbeat",
  "versatile",
  "vigorous",
  "vivacious",
  "warm",
  "warmhearted",
  "willing",
  "wise",
  "witty",
  "wonderful",
];

export const holeNouns = [
  "abyss",
  "achievement",
  "brainchild",
  "burrow",
  "cave",
  "cavern",
  "cavity",
  "chamber",
  "chasm",
  "chef-d'oeuvre",
  "cistern",
  "cleft",
  "concept",
  "crack",
  "cranny",
  "crater",
  "den",
  "dent",
  "depression",
  "dimple",
  "effort",
  "enterprise",
  "excavation",
  "exertion",
  "experiment",
  "eyelet",
  "fissure",
  "fracture",
  "gorge",
  "hollow",
  "hovel",
  "labor",
  "lacuna",
  "lair",
  "leak",
  "nest",
  "niche",
  "orifice",
  "outlet",
  "passage",
  "perforation",
  "piece",
  "pit",
  "pocket",
  "pockmark",
  "portfolio",
  "production",
  "scooping",
  "shaft",
  "shelter",
  "space",
  "tunnel",
  "undertaking",
  "vacuity",
  "venture",
  "void",
  "window",
  "work",
];

export const greatAdjectives = [
  "amazing",
  "ass-blasting",
  "astonishing",
  "astounding",
  "awe-inspiring",
  "awesome",
  "exquisite",
  "extraordinary",
  "eye-popping",
  "fabulous",
  "glorious",
  "grandiose",
  "inspiring",
  "magnificent",
  "masterful",
  "munificent",
  "opposite-of-towering",
  "palatial",
  "prodigious",
  "superhuman",
  "unreal",
  "wonderful",
];
